<template>
  <div class="bio_link_main_view">

    <div class="view_inner d-flex flex-column">

      <div class="view_top d-flex align-items-center">
        <h4 class="title">Add Links</h4>
        <div class="view-right d-flex align-items-center ml-auto">
          <bio-shortened-url></bio-shortened-url>
          <router-link class="cross_icon ml-auto" :to="{name: 'bio_links'}">
            <i class="fal fa-times"></i>
          </router-link>
        </div>
      </div>

      <div class="view_center">
        <div class="steps_dot mb-3">
          <router-link :to="{name: 'select_content'}"
                       class="btn---cta light-blue btn-round">
            <span>Go Back</span>
          </router-link>
          <div class="circles">
            <span class="dotted_circle active"></span>
          </div>


          <button @click.prevent="validateAndStoreBlock()" :disabled="!isLinkCreated" class="btn---cta btn-blue btn-round">
                        <span>
                            <template v-if="!getBioLinkContentSectionAdd._id">
                                Save Block
                            </template>
                            <template v-else>
                                Update Block
                            </template>
                        </span>
          </button>
        </div>

        <div class="input-block-list">
          <div class="block-heading">
            <h2>Links</h2>

            <p>Add multiple website links to your profile with the Link Block.
              Use this block to create compelling call-to-actions for things that matter to you.
              You can add up to 5 links!
            </p>
          </div>
          <bio-show-title-snippet></bio-show-title-snippet>
          <div class="mt-3 input_icon_text d-flex align-items-center  "
               :class="{'input-field-error': title_required || title_length}">
            <div class="content">
              <div class="url">Add A Title</div>
              <div class="name">
                <div class="validation_input">
                  <input class="" type="text" placeholder="Name for your links section"
                         :disabled="!getBioLinkContentSectionAdd.show_title"
                         v-model="getBioLinkContentSectionAdd.title" data-cy="link-block-title">
                </div>
              </div>
            </div>
          </div>
          <span class="input-error" v-if="title_required">Title is required.</span>
          <span class="input-error" v-else-if="title_length">The title should not be greater than 100 characters.</span>
          <!-- add links section -->
          <div class="add-block-list">
            <draggable :list="getBioLinkContentSectionAdd.links" :move="checkMove" :disabled="!enabled"
                       ghost-class="ghost"
                       @start="dragging = true" @end="dragging = false" v-model="getBioLinkContentSectionAdd.links">
              <div class="add-block-item" v-for="(section, index) in getBioLinkContentSectionAdd.links"
                   :key="index">
                <div class="block-inner">
                  <div class="item-top-area d-flex align-items-center">
                    <div class="item-right ml-auto">
                      <div class="switch-toggle">
                        <span>Show Links</span>
                        <label class="switch" :for="section.id">
                          <input v-model="section.show_links" type="checkbox" :id="section.id">
                          <div class="slider round"></div>
                        </label>
                      </div>

                      <!--<i class="far fa-pencil"></i>-->
                      <i v-tooltip.top-center="'Remove'" class="far fa-trash"
                         @click="removeBioLinkContentSection(index)"
                         v-if="getBioLinkContentSectionAdd.links.length > 1"></i>
                    </div>
                  </div>
                  <div class="mt-3 input_icon_text d-flex align-items-center  "
                       :class="{'input-field-error': section.title_required || section.title_length }">
                    <div class="content">
                      <div class="url">Title</div>
                      <div class="name">
                        <div class="validation_input">
                          <input @keyup="bioInputChange(section)" class="" type="text"
                                 placeholder="Add a Title..."
                                 :disabled="!section.show_links"
                                 v-model="section.title" data-cy="link-title">
                        </div>
                      </div>
                    </div>

                  </div>
                  <span class="input-error" v-if="section.title_required">Title can not be empty.</span>
                  <span class="input-error" v-else-if="section.title_length">The title should not be greater than 100 characters.</span>

                  <div class="mt-3 input_icon_text d-flex align-items-center  "
                       :class="{'input-field-error': section.validations && (section.validations.url || section.validations.urlLength || section.validations.urlValid)}">
                    <div class="content">
                      <div class="url">URL</div>
                      <div class="name">
                        <div class="validation_input">
                          <url
                            :value="section.url"
                            @onEnter="validateAndStoreBlock($event)"
                            @input="bioUrlChange($event,section)"
                            :disabled="!section.show_links"
                            :validations="section.validations"
                            v-bind:blur="() => section.url = focusoutConcatenation(section.url)"
                            ref="urlRef"
                            data-cy="link-url"
                            placeholder="i-e. 'https://website.com', ‘tel:+01273123456’, 'mailto:support@website.com'"></url>
                          <!--                          <input @keyup="bioUrlChange(section)" class="" type="text"-->
                          <!--                                 placeholder="i-e. https://abc.com, +49************, support@abc.com"-->
                          <!--                                 :disabled="!section.show_links"-->
                          <!--                                 @blur="section.url = focusoutConcatenation(section.url)"-->
                          <!--                                 v-model="section.url" data-cy="link-url">-->
                        </div>
                      </div>
                    </div>
                    <div class="avatar mr-0 ml-3" v-if="section.emit">
                      <clip-loader :color="'#168eea'" :size="'14px'"></clip-loader>
                    </div>
                  </div>
                  <span class="input-error"
                        v-if="section.validations && section.validations.url">{{ bioLinksSectionMessages.url }}</span>
                  <span class="input-error" v-else-if="section.validations && section.validations.phoneValid">{{
                      bioLinksSectionMessages.phoneValid
                    }}</span>
                  <span class="input-error" v-else-if="section.validations && section.validations.mailValid">{{
                      bioLinksSectionMessages.mailValid
                    }}</span>
                  <span class="input-error" v-else-if="section.validations && section.validations.urlLength">{{
                      bioLinksSectionMessages.urlLength
                    }}</span>
                  <span class="input-error" v-else-if="section.validations && section.validations.urlValid">{{
                      bioLinksSectionMessages.urlValid
                    }}</span>

                </div>
              </div>
            </draggable>
          </div>
          <div class="btn-block mt-3 d-flex align-items-center">
            <div class="btn-right ml-auto">
              <button @click="addLinkOption()" class="btn---cta  light-blue btn-round">
                <span>Add More Links</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {bioLinkTypes} from '@/state/modules/mutation-types'
import {mapGetters} from 'vuex'
import BioShortenedURL from './BioShortenedURL'
import BioShowTitleSnippet from './short/BioShowTitleSnippet'
import {validationSocket} from '@/config/api'
import debounce from 'lodash.debounce'
import draggable from 'vuedraggable'
import URL from '@/ui/URL'
import {bioLinksSectionValidations, bioLinksSectionMessages} from '../../../../../common/attributes'

export default ({
  data () {
    return {
      enabled: true,
      title_required: false,
      title_length: false,
      validations: {
        url: false,
        urlValid: false,
        urlLength: false
      },
      bioLinksSectionMessages: bioLinksSectionMessages
    }
  },
  components: {
    'bio-shortened-url': BioShortenedURL,
    BioShowTitleSnippet,
    'url': URL,
    draggable
  },
  created () {
    this.isBioLinkFirstStepVerified()

    // While updating the links section we are re-adding the bio links section validations
    if (this.getBioLinkContentSectionAdd._id && this.getBioLinkContentSectionAdd.links) {
      this.getBioLinkContentSectionAdd.links.forEach(link => {
        if (!link.validations) {
          link.validations = {...bioLinksSectionValidations}
        }
      })
      return
    }

    this.addLinkOption()

  },
  computed: {
    ...mapGetters([
      'getBioLinkContentSectionAdd',
      'getProfile'
    ]),
    isLinkCreated () {
      return this.getBioLinkContentSectionAdd.links.some(item => item.show_links === true)
    }
  },
  mounted () {
    validationSocket().on('url-preview-channel:' + this.getProfile._id, res => {
      if (res.id) {
        let item = this.getBioLinkContentSectionAdd.links.find(item => item.id === res.id)
        if (!item) {
          return false
        }

        // set the item emit to false so that we can get the new preview
        item.emit = false

        if (res.status) {
          if (res.meta && res.meta.title) {
            item.title = res.meta.title
            item.title_required = false
          }

        }
      }
    })
  },
  methods: {
    addLinkOption () {
      let arrayItem = {
        id: Math.floor(Math.random() * (99999999 - 1 + 1)),
        title: null,
        title_required: false,
        url: null,
        validations: {...bioLinksSectionValidations},
        show_links: true,
        emit: false
      }
      this.$store.commit(bioLinkTypes.SET_BIO_CONTENT_SECTION_ADD_LINKS, arrayItem)
    },
    async checkMove () {

    },
    async validateAndStoreBlock () {
      let result = true
      if (this.getBioLinkContentSectionAdd.show_title) {
        this.title_required = this.requiredCheck(this.getBioLinkContentSectionAdd.title)
      } else {
        this.title_required = false
      }

      if (this.getBioLinkContentSectionAdd.links.length > 0) {
        this.getBioLinkContentSectionAdd.links.forEach(item => {
            if (item.show_links) {
              item.title_required = this.requiredCheck(item.title)
              item.title_length = !this.maxLength(item.title, 100)
              if (this.telCheck(item.url)) {
                item.validations.phoneValid = this.checkTel(item.url.substring(4))
              } else if (this.mailToCheck(item.url)) {
                item.validations.mailValid = this.checkEmail(item.url.substring(7))
              } else {
                item.validations.url = this.requiredCheck(item.url)
                item.validations.urlValid = !this.isValidURL(item.url)
              }
              // required validations check

              if (item.title_required && result) result = false
              if (item.title_length && result) result = false
              if (item.validations.url && result) result = false
              if (item.validations.urlValid && result) result = false
              if (item.validations.urlLength && result) result = false
              if (item.validations.phoneValid && result) result = false
              if (item.validations.mailValid && result) result = false
            }
          }
        )
        if (result && !this.title_required && !this.title_length) {

          let res = await this.$store.dispatch('storeBioContentSection')
          if (res && res.data.status) {
            this.$router.push({name: 'select_content'})
          } else {
            this.alertMessage(res.data.message, 'error')
          }

          // once the link has been made, then we will store the section
        }
      } else {
        this.$store.dispatch('toastNotification', {
          message: 'Please add links before you can save them',
          type: 'error'
        })
      }
    },
    removeBioLinkContentSection (index) {
      var self = this
      swal({
          title: 'Are you sure ?',
          type: 'warning',
          text: '',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, Delete it!',
          cancelButtonText: 'No, Cancel!',
          closeOnConfirm: true,
          customClass: 'sweetAlert_box',
          closeOnCancel: true,
          animation: false
        },
        function (isConfirm) {
          if (isConfirm) {
            self.getBioLinkContentSectionAdd.links.splice(index, 1)
          } else {
            swal()
          }
        })
    },
    bioInputChange: debounce(function (item) {
      item.title_required = this.requiredCheck(item.title)
      item.title_length = !this.maxLength(item.title, 100)
    }, 300),
    bioUrlChange: debounce(function (event, item) {
      item.url = event
      const result = Object.keys(item.validations).every(k => item.validations[k] === false)
      if (result && this.requiredCheck(item.title)) {
        // emit the event

        if (!item.emit) {
          validationSocket().emit('url-preview-channel:' + this.getProfile._id, {
            url: item.url.trim(),
            id: item.id,
            user_id: this.getProfile._id
          })
          item.emit = true
        }
      }
    })
  },
  watch: {
    'getBioLinkContentSectionAdd.title' (value) {
      if (value && value.length > 0) {
        this.title_required = false
        this.title_length = !this.maxLength(value, 100)
      }
    }
  }

})
</script>

<style scoped lang="less">
.bio_link_layout .bio_link_main_view .view_inner .view_center .steps_dot .circles {
  width: 495px;
  text-align: center;
  display: inline-block;
}

.add-block-list {
  .add-block-item {
    .block-inner {
      cursor: move;
    }
  }
}
</style>
